import { graphql } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import * as C from '../components'
import { AddToCart, Details, Images, Info } from '../bundle'
import { Parts } from '../bundle'
import * as S from '../styles'

const Mobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

const Desktop = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`

export const BundlesQ = graphql`
  query($slug: String) {
    allContentfulBundle(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          ...BundleFragment
        }
      }
    }
  }
`

const Bundle: React.SFC<any> = props => {
  const bundle = props.data.allContentfulBundle.edges[0].node
  const soldOut = bundle.soldOut

  return (
    <C.App headerTheme="dark">
      <S.Section className="intro product-intro">
        <S.Wrapper>
          <Mobile>
            <Info bundle={bundle} />
            {bundle.images && <Images images={bundle.images} />}
            <Details bundle={bundle} />
            <AddToCart
              variantId={bundle.shopify_product[0].variantId}
              soldOut={soldOut}
            />
          </Mobile>
          <Desktop>
            <S.Grid>
              <S.GridItem
                width="48%"
                breakpoints={{
                  767: '100%',
                }}>
                <Info bundle={bundle} />
                <Details bundle={bundle} />
                <AddToCart
                  variantId={bundle.shopify_product[0].variantId}
                  soldOut={soldOut}
                />
              </S.GridItem>
              <S.GridItem
                width="48%"
                breakpoints={{
                  767: '100%',
                }}>
                {bundle.images && <Images images={bundle.images} />}
              </S.GridItem>
            </S.Grid>
          </Desktop>
        </S.Wrapper>
      </S.Section>
      <Parts products={bundle.products} />
      <C.JoinCommunity />
    </C.App>
  )
}

export default Bundle
