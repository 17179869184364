import * as React from 'react'
import styled from 'styled-components'
import * as S from '../styles'

export const RegularPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`

const Info: React.SFC<any> = ({ bundle }) => {
  if (!bundle) {
    return null
  }

  return (
    <div>
      <S.Heading
        as="h2"
        className="small"
        style={{ marginBottom: 10, color: '#ABB9B5' }}>
        {bundle.name}
      </S.Heading>
      <S.Heading as="h1" style={{ marginBottom: 10, color: '#002821' }}>
        {bundle.name}
      </S.Heading>
      {bundle.regularPrice ? (
        <RegularPriceWrapper>
          <S.Text
            className="large"
            style={{
              color: '#ABB9B5',
              marginBottom: 10,
              fontWeight: 600,
              textDecoration: 'line-through',
              marginRight: 10,
            }}>
            ${bundle.regularPrice}
          </S.Text>
          <S.Text
            className="large"
            style={{
              color: '#002821',
              marginBottom: 10,
              fontWeight: 600,
            }}>
            ${bundle.price}
          </S.Text>
        </RegularPriceWrapper>
      ) : (
        <S.Text
          className="large"
          style={{ color: '#002821', marginBottom: 10, fontWeight: 600 }}>
          ${bundle.price}
        </S.Text>
      )}
    </div>
  )
}

export default Info
