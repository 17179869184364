import * as React from 'react'
import styled from 'styled-components'

const Main = styled.div`
  display: flex;
  justify-content: center;

  img {
    @media (max-width: 767px) {
      max-height: 250px;
    }
  }
`

const Thumbs = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`

const Thumb = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 50%;
  margin: 0 10px;

  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
  }

  &.is-active {
    border-color: black;
  }
`

const DesktopImages: React.SFC<any> = ({ images }) => {
  if (!images) {
    return null
  }

  const [active, setActive] = React.useState(0)
  const selected = images.find((i, index) => index === active)

  return (
    <>
      <Main>
        <img src={selected && selected.fluid.src} />
      </Main>
      <Thumbs>
        {images.map((i, index) => (
          <Thumb
            key={index}
            className={active === index ? 'is-active' : ''}
            onClick={() => setActive(index)}
            style={{
              background: `url(${i.fluid.src}) center / cover no-repeat`,
            }}
          />
        ))}
      </Thumbs>
    </>
  )
}

export default DesktopImages
