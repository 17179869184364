import * as React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import * as S from '../styles'

const Text = styled(S.Text)`
  font-size: 16px;
  color: #002821;
`

const Subheading = styled(Text)`
  font-weight: 600;
  margin-bottom: 10px;

  &.details-heading {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
`

const Description = styled.div`
  line-height: 24px;
  margin-bottom: 15px;

  p {
    margin-bottom: 15px;
  }
`

const Details: React.SFC<any> = ({ bundle }) => {
  if (!bundle) {
    return null
  }

  return (
    <div>
      <Subheading className="details-heading">Details</Subheading>
      <Description>
        <ReactMarkdown source={bundle.description.description} />
      </Description>
    </div>
  )
}

export default Details
