import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
import styled from 'styled-components'
import wineIcon from '../assets/wine-icon.svg'
import * as S from '../styles'

const Image = styled.img`
  margin-bottom: 3em;
  margin-left: -30px;
  max-width: calc(100% + 30px);
`

const SubSection = styled.div`
  display: flex;
  flex-direction: column;

  &.product-description {
    justify-content: center;
  }

  &.product-image {
    justify-content: center;
    align-items: center;
  }

  img {
    width: fit-content;
    margin-bottom: 15px;
  }

  ${S.Heading} {
    margin-bottom: 15px;
  }
`

interface Props {
  products: any
}

const Parts: React.SFC<Props> = ({ products }) => {
  if (!products) return null

  return products.map(({ mainImage, name, slug, description }, index) => {
    const direction = index % 2 ? 'row-reverse' : 'row'
    return (
      <S.Section className="sub-parts" key={slug}>
        <S.Section>
          <S.Wrapper>
            <Link to={`/shop/${slug}`} style={{ textDecoration: 'none' }}>
              <S.Grid
                style={{
                  flexDirection: direction,
                  padding: '20px 0',
                }}>
                <S.GridItem
                  width="46%"
                  breakpoints={{
                    767: '100%',
                  }}>
                  <SubSection className="product-description">
                    <img src={wineIcon} />
                    <S.Heading as="h3" className="medium">
                      {name}
                    </S.Heading>
                    <S.Text>
                      <ReactMarkdown source={description.description} />
                    </S.Text>
                  </SubSection>
                </S.GridItem>
                <S.GridItem
                  style={{ margin: 'auto' }}
                  width="46%"
                  breakpoints={{
                    767: '100%',
                  }}>
                  <SubSection className="product-image">
                    <Image src={mainImage.fluid.src} alt={name} />
                  </SubSection>
                </S.GridItem>
              </S.Grid>
            </Link>
          </S.Wrapper>
        </S.Section>
      </S.Section>
    )
  })
}

export default Parts
