import * as React from 'react'

import * as S from '../styles'
import Quantity from '../product/Quantity'
import { useCart } from '../useCart'
import { usePromiseWithLoading } from '../usePromiseWithLoading'

interface Props {
  variantId: string
  soldOut: boolean
}

const AddToCart: React.SFC<Props> = ({ variantId, soldOut }) => {
  const cart = useCart()
  const [quantity, setQuantity] = React.useState(1)
  const addToCart = async () => {
    await cart.addToCart(variantId, quantity)

    if (!cart.isOpen) {
      cart.toggleOpen()
    }
  }

  const [add, loading] = usePromiseWithLoading(addToCart)

  return (
    <div style={{ display: 'flex' }}>
      {soldOut ? (
        ''
      ) : (
        <Quantity quantity={quantity} setQuantity={setQuantity} />
      )}
      {soldOut ? (
        'currently sold out'
      ) : loading ? (
        <S.Button className="darkGreen loadingWhite">Add to Cart</S.Button>
      ) : (
        <S.Button className="darkGreen" onClick={add as any}>
          Add to Cart
        </S.Button>
      )}
    </div>
  )
}

export default AddToCart
